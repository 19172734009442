@use 'variables';
@import 'variables';
@import '~bootstrap/scss/bootstrap';

[data-bs-theme='dark'] {
  --bs-body-bg: #121212;
}

body > main {
  min-height: calc(
    100vh - variables.$page-header-height - variables.$page-footer-height -
      variables.$page-header-spacing
  );
  margin-top: variables.$page-header-height + variables.$page-header-spacing;

  .page-header + .navbar {
    margin-top: -24px;
    @extend .border-bottom;
    margin-bottom: 24px;
  }
}

.page-header {
  position: absolute;
  top: 0;
  height: variables.$page-header-height;
  width: 100%;
  @extend .border-bottom;
  @extend .bg-light;
  [data-bs-theme='dark'] & {
    @extend .bg-dark;
  }

  .user-actions {
    @extend .align-items-center;
    @extend .justify-content-end;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .header-search {
    min-width: 200px;
    @include media-breakpoint-down(md) {
      flex-grow: 1;
    }
  }
}

.page-header + main {
  padding-top: variables.$page-header-height;
}

.page-footer {
  height: variables.$page-footer-height;
  @extend .border-top;
  @extend .bg-light;
  [data-bs-theme='dark'] & {
    @extend .bg-dark;
  }

  .navbar-brand {
    @extend .fs-6;
  }
}

.dropdown[data-flip] > .dropdown-menu[data-bs-popper] {
  right: 0;
  left: auto;
}
